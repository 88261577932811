/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import 'photoswipe/dist/photoswipe.css';

import React from 'react';
import { Gallery, Item } from 'react-photoswipe-gallery';

import Page from '../../components/page/Page';
import PageHeader from '../../components/pageHeader/PageHeader';
import galleryImage2 from '../../images/DSC_1450.jpeg';
import galleryImage2Thumbnail from '../../images/DSC_1450_thumbnail.jpeg';
import headerImage from '../../images/DSC_4539.jpeg';
import galleryImage1 from '../../images/DSC_8556.jpeg';
import galleryImage1Thumbnail from '../../images/DSC_8556_thumbnail.jpeg';
import galleryImage3 from '../../images/DSC_8626.jpeg';
import galleryImage3Thumbnail from '../../images/DSC_8626_thumbnail.jpeg';
import styles from './program.module.scss';

const ProgramToddlerLearning = (): JSX.Element => (
  <Page title="Toddler Learning Room" pageClassName={styles.page}>
    <PageHeader title="Toddler Learning Room" image={headerImage} />
    <div className={styles.content}>
      <h1>Toddlers are dynamic and busy</h1>
      <p>
        Our toddler programs provide the space for exploration and discovery, so
        they can interact with and understand the world around them.{' '}
      </p>
      <p>Our toddler learning program includes:</p>
      <ul>
        <li>
          specific menu designed with nutritious and delicious meals prepared
          fresh
        </li>
        <li>daily communication with families via Kinderm8 app</li>
        <li>sunscreen</li>
        <li>
          learning environments to support all areas of toddler development
        </li>
        <li>safe and clean resources to explore, learn and play</li>
        <li>quality nappies and wipes</li>
      </ul>
      <div className={styles.ctaLinkContainer}>
        <a
          href="https://ccs-earlylearningonrossi.kinderm8.com.au/enrolment-form"
          target="_blank"
          className={styles.ctaLink}
          rel="noopener noreferrer"
        >
          <button className={styles.solidButton} type="button">
            Enrol Now
          </button>
        </a>
      </div>

      <Gallery options={{ bgOpacity: 0.9 }}>
        <ul className={styles.imageGallery}>
          <Item
            original={galleryImage1}
            thumbnail={galleryImage1Thumbnail}
            width="1200"
            height="800"
          >
            {({ ref, open }) => (
              <li>
                <img
                  ref={ref as React.MutableRefObject<HTMLImageElement>}
                  onClick={open}
                  src={galleryImage1Thumbnail}
                  alt="1"
                  className={styles.galleryImage}
                />
              </li>
            )}
          </Item>
          <Item
            original={galleryImage2}
            thumbnail={galleryImage2Thumbnail}
            width="1200"
            height="800"
          >
            {({ ref, open }) => (
              <li>
                <img
                  ref={ref as React.MutableRefObject<HTMLImageElement>}
                  onClick={open}
                  src={galleryImage2Thumbnail}
                  alt="1"
                  className={styles.galleryImage}
                />
              </li>
            )}
          </Item>
          <Item
            original={galleryImage3}
            thumbnail={galleryImage3Thumbnail}
            width="1200"
            height="800"
          >
            {({ ref, open }) => (
              <li>
                <img
                  ref={ref as React.MutableRefObject<HTMLImageElement>}
                  onClick={open}
                  src={galleryImage3Thumbnail}
                  alt="1"
                  className={styles.galleryImage}
                />
              </li>
            )}
          </Item>
        </ul>
      </Gallery>
    </div>
  </Page>
);

export default ProgramToddlerLearning;

import React from 'react';

import Page from '../components/page/Page';
import PageHeader from '../components/pageHeader/PageHeader';
import team from '../images/DSC_4455.jpeg';
import holdingHands from '../images/holding_hands_icon.png';
import data from '../site-data.json';
import styles from './careers.module.scss';

const Careers = (): JSX.Element => (
  <Page title={`${data.siteName} - Careers`}>
    <PageHeader title="Careers" image={team} />
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <p>
          Having lived and breathed the childcare industry for over 30 years,
          Erin knows how it can be such a rewarding career. She also understands
          and acknowledges its challenges for educators.
        </p>
        <p>
          This is why our teams are offered additional benefits you won&apos;t
          see anywhere else.
        </p>
        <p>Would you like...?</p>
        <ul>
          <li>Life/work balance</li>
          <li>4 hours of paid training before you even start</li>
          <li>Uniforms provided </li>
          <li>Discounted childcare up to 95%</li>
          <li>Paid monthly staff meetings</li>
          <li>Room resource budget</li>
          <li>Monthly professional development budget</li>
          <li>Arts and craft budget</li>
          <li>Professional Development exchange program</li>
          <li>Career advancement opportunities</li>
          <li>One week free holiday per year in Gold Coast (for Directors)</li>
          <li>
            All staff receive up to 50% discounted holiday accommodation on the
            Gold Coast every year.
          </li>
        </ul>
        <p>
          If you answered yes to any of the questions above and you&apos;re
          passionate about a career in early childhood, send your resume to:{' '}
          <a
            href={`mailto:${data.email}?subject=Careers Enquiry`}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.itemLink}
          >
            <p className={styles.bodyText}>
              {`${data.email?.replace('@', '@\u200B') || ''}`}
            </p>
          </a>
        </p>
        <div className={styles.textImageWrapper}>
          <div className={styles.textWrapper}>
            <p>Be sure to include these with your CV:</p>
            <ol>
              <li>
                Shine a light on all the things that make you a great employee
              </li>
              <li>
                Share your level of experience working with children and
                families
              </li>
              <li>
                Let us know why you think you&apos;ll be a good fit for the team
              </li>
              <li>Personal and 2 x professional referees who we can contact</li>
            </ol>
          </div>
          <div className={styles.imageWrapper}>
            <img src={holdingHands} alt="Holding Hands" />
          </div>
        </div>
      </div>
    </div>
  </Page>
);

export default Careers;

import React from 'react';
import { Link } from 'react-router-dom';

import Countdown from '../components/countdown/Countdown';
import Message from '../components/message/Message';
import Page from '../components/page/Page';
import PageHeader from '../components/pageHeader/PageHeader';
import galleryImage2 from '../images/DSC_1468.jpeg';
import galleryImage3 from '../images/DSC_7118.jpeg';
import galleryImage1 from '../images/DSC_9127.jpeg';
import troyAndErin from '../images/troy_erin_illustration.png';
import data from '../site-data.json';
import styles from './about.module.scss';

const About = (): JSX.Element => (
  <Page title={`${data.siteName} - About Us`}>
    <PageHeader
      title="About Us"
      image={galleryImage1}
      containerClassName={styles.pageHeaderContainer}
    />
    <Countdown
      image={galleryImage2}
      title="Our Centre"
      content={
        <>
          <p>
            Our centre is always striving to go above and beyond to ensure our
            village of children, families and staff are safe, secure and cared
            for.
          </p>
          <p>
            From the moment you walk through the front door you&apos;ll feel a
            warm sense of home-away-from-home with brand new modern furnishings,
            equipment and resources that are well maintained.
          </p>
          <p>
            We are a child-safe early education service that is committed to
            implementing the{' '}
            <a
              href="https://ocg.nsw.gov.au/child-safe-scheme"
              target="_blank"
              rel="noopener noreferrer"
            >
              Child Safe Standards
            </a>
            . This includes ensuring our visitors have a current Working With
            Children Check and our educators are continually trained in child
            protection to keep them safe.
          </p>
          <p>
            We believe learning can happen anywhere and anytime. Our
            environments are specifically designed to support opportunities to
            learn through play - all day, every day.
          </p>
        </>
      }
      backgroundColour="accent4"
    />
    <Countdown
      imageLeft
      image={galleryImage3}
      title="Team"
      content={
        <>
          <p>
            Our teams are vital to the high level of care we provide your
            family. Which is why we have developed an induction process that
            includes onsite and online training prior to our educators working
            with our families.
          </p>
          <p>
            We provide specialised{' '}
            <a
              href="https://www.circleofsecurityinternational.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Circle Of Security
            </a>{' '}
            International Training from our in-house certified facilitator. This
            specialised training ensures our educators;
          </p>
          <ul>
            <li>
              Provide the freedom and support to develop the confidence needed
              for your child to discover their world
            </li>
            <li>
              Help your child feel assured they can come and seek help and have
              their cup filled when they need and,
            </li>
            <li>
              Our educators are committed to your child&apos;s connection to
              their world by caring in a kind and secure way
            </li>
          </ul>
          <p>
            This support and training is also offered to families for free
            throughout the year.
          </p>
          <p>
            All of our staff are fully trained in first aid and have monthly
            professional development to support continual growth and innovation.
          </p>
          <p>
            As much as we want your children to have a great day, every day - we
            want this for our educators too. Read more about our staff benefits.
          </p>
          <div className={styles.ctaCentered}>
            <Link to="/careers" className={styles.ctaLink}>
              <button className={styles.solidButton} type="button">
                Read More
              </button>
            </Link>
          </div>
        </>
      }
      backgroundColour="accent4"
    />
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.title}>
          <h1>Our Story</h1>
        </div>
        <h2>How it began</h2>
        <p>
          At the age of 23, Erin was working as a Diploma trained educator at a
          centre that was about to be closed down. She decided it was not good
          enough for those families to lose access to childcare and did
          something very brave. Erin moved mountains and purchased that centre
          to ensure it could stay open. Looking back Erin would say, she thought
          she knew everything about running a childcare centre at 23 years of
          age. Then she found out - there was a lot to learn.
        </p>
        <div className={styles.textImageWrapper}>
          <div className={styles.textWrapper}>
            <h2>How it&apos;s going</h2>
            <p>
              Which is exactly what Erin did. She continued her studies and
              graduated with a Bachelor of Early Childhood Education. Erin then
              took that learning and experience of owning her first centre to
              become a corporate early childhood consultant, managing 80 centres
              statewide.
            </p>
            <p>
              With young children of her own and needing a better work/life
              balance Erin established a consultancy company to support
              Childcare Centres throughout Australia. Once this grew beyond the
              ability for a good work/life balance she knew it was time for a
              change.
            </p>
          </div>
          <div className={styles.imageWrapper}>
            <img src={troyAndErin} alt="About Us" />
          </div>
        </div>
        <p>
          Erin and her husband Troy, own and operate a smaller number of
          boutique centres to provide a level of care that feels like an
          extension of home. They provide all the love and education in the same
          way they wanted for their own four children.
        </p>
        <p>
          In 2019, Erin and Troy established and funded an inhouse ‘Giving Hand
          Foundation’ which supports the people in their village in times of
          need. Senior staff are asked to nominate families or staff who may be
          doing it tough or just need a helping hand every year as a way of
          continually giving back to their community.
        </p>
      </div>
    </div>
    <Message
      title="‘Who dares to teach must never cease to learn’ — John Cotton Dana"
      backgroundColour="accent4"
    />
  </Page>
);

export default About;
